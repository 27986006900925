<!--
 * @Descripttion: 参加的项目
 * @version: 1.0
 * @Author: Niklaus
 * @Date: 2021-08-26 20:50:40
-->
<template>
  <el-main>
    <h1 class="base-title">参与的项目</h1>
    <div class="search mt">
      <el-input  placeholder="请输入内容"  v-model="searchVal"  clearable :input-style="inputStyle" @clear="onProjectList"></el-input>
      <el-button type="primary" @click="onProjectList">搜索</el-button>
    </div>

    <el-table :data="tableData" style="width: 100%">
      <el-table-column label="#" type="index" min-width="5%">
      </el-table-column>
      <el-table-column label="竞价结果" min-width="10%">
        <template #default="scope">
          <span v-if="scope.row.resultStatus == '01'||scope.row.resultStatus == '02'||scope.row.resultStatus == '03' " class="el-link el-link--primary">已成交</span>
          <span v-else class="el-link el-link--danger">未成交</span>
        </template>
      </el-table-column>
      <el-table-column label="项目编号" min-width="13%">
        <template #default="scope">
          <span >{{ scope.row.projectNo }}</span>
        </template>
      </el-table-column>
      <el-table-column label="项目名称" min-width="20%">
        <template #default="scope">
          <span >{{ scope.row.projectName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="项目类型" min-width="7%">
        <template #default="scope">
          <span >{{ scope.row.typeName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="报价方式" min-width="10%">
        <template #default="scope">
          <span class="el-link el-link--primary">{{ scope.row.quotationMethod | purchaseType }}</span>
        </template>
      </el-table-column>
      <el-table-column label="竞价时间" min-width="10%">
        <template #default="scope">
          <span>{{ scope.row.biddBeginDatetime }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="470px">
        <template #default="scope">
          <el-button size="mini" type="success" @click="handleBiddingNotice(scope.row)">竞价邀请</el-button>
          <el-button size="mini" type="info" @click="handleDetail(scope.row.id)">查看项目</el-button>
          <el-button type="primary" size="mini" @click="handleConfirm(scope.row)">竞价结果</el-button>
          <el-button type="default" size="mini" @click="handleRecord(scope.row, scope.row.id)">报价记录</el-button>
          <el-button v-if="scope.row.processDetailStatus == '22'" type="danger" size="mini" @click="showReason(scope.row.pauseReason)">终止原因</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="block page-pagination">
      <el-pagination
        prev-text="上一页"
        next-text="下一页"
        layout="prev, pager, next"
        @current-change="goPage"
        :total="total">
      </el-pagination>
    </div>

    <!-- 右边抽屉： 显示报价明细 -->
    <el-drawer
      title="报价记录"
      size="45%"
      :visible.sync="showRecord"
    >
      <!-- <div class="priceRecord">
        <el-row :gutter="20" v-for="(round,index) in priceRecords" :key="index">
          <el-col :span="6">
            {{ '第' + round.roundsNo +'轮次' }}
          </el-col>
          <el-col :span="18">
            <el-row :gutter="20" v-for="item in round.list" :key="item.itemId">
              <el-col :span="8">{{ item.itemName }}</el-col>
              <el-col :span="16">
                <el-row :gutter="20" v-for="r in item.list" :key="r.price">
                  <el-col :span="8">{{ r.price }}</el-col>
                  <el-col :span="16">{{ r.createTime }}</el-col>
                </el-row>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </div> -->
      <el-main>


      <el-table
        :data="priceRecords"
        border
        style="width: 100%"
      >
        <el-table-column
          prop="roundsNo"
          label="轮次"
          width="180">
        </el-table-column>
        <el-table-column
          prop="itemName"
          label="标的名称">
        </el-table-column>
        <el-table-column
          prop="price"
          :label="priceText">
        </el-table-column>
        <el-table-column
          prop="createTime"
          label="报价时间">
        </el-table-column>
      </el-table>
      </el-main>
    </el-drawer>
  </el-main>
</template>

<script>
import {httpPost,httpGet,httpPut} from '@/utils/http'
import global from '@/common/global';
import {countDown} from '@/utils/date';
export default {
  name: 'OnGoing',
  data(){
    return {
      searchVal: '',
      inputStyle:{width:'300px'},
      tableData: [],
      queryParam: {
          supplierStoreId: "",
          partakeStatus:"",
          projectName: "",
          projectNo: "",
          processStatus: "",
          processDetailStatus: ""
      },
      pageParams:{
          pageNo:1,
          pageSize:10
      },
      urls:{
        detailProjectList: "/ewbid/bidd/supplierProjectController/list",
        simpleProjectList: "/ewbid/bidd/supplierProjectController/simpleDataList",
        bidingBulletin: '/ewbid/bulletin/tbEwProjectBulletin/getProjectBiddingNotice',
        priceRecord: '/ewbid/bidding/tbEwProjectPriceRecord/getRecordByRound'
      },
      total: 0,
      intervals:[],
      priceRecords:[],
      priceTreeRecords:[],
      showRecord: false,
      countRecord:[],
      priceText: '报价金额'
    }
  },

   beforeMount(){
    this.queryParam.supplierStoreId = this.$ls.get("storeInfo").id
    this.onProjectList();
  },
  /**
   * 页面销毁前，关闭该页面定义的所有定时器
   */
  beforeDestroy() {
    this.intervals.forEach(item => {
      clearInterval(item)
    })
  },

  methods: {
     /**
     * @Description: 查看项目竞价公告
     * @Author: Niklaus
     * @Date: 2021-09-05 15:32:54
     */
    handleBiddingNotice(row) {
      let data  = {
        projectId: row.id,
        bulletinType: '01',
        releaseStatus: '01',
        auditStatus: '01'
      }
      httpGet(global.gateway + this.urls.bidingBulletin,data).then(res =>{
        if(res.data.success)
          window.open(res.data.result.bulletinUrl)
        else
          this.$message.error("查询公告数据出错")
      })
    },

    handleDetail(id) {
      this.$router.push({
        name:'project',
        query:{
          projectId:id
        }
      })
    },
      handleDelete(index, row) {
        this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      },
    //查询项目列表
    onProjectList(){
      this.queryParam.projectName = this.searchVal.trim();
      this.queryParam.partakeStatus ='1';//0未参与1参与
      let params = Object.assign({}, this.queryParam)
      params.pageNo = this.pageParams.pageNo;
      params.pageSize = this.pageParams.pageSize;
      httpGet(global.gateway+this.urls.simpleProjectList,params).then(res=>{
        // console.log(JSON.stringify(res.data.result.records[0]));
        this.tableData = res.data.result.records;
        this.total = res.data.result.total;
        //创建定时器
        this.tableData.map(row =>{
          let t = setInterval(() => {
            this.$set(row,'ttt', countDown(new Date(),new Date(row.biddBeginDatetime)))
          }, 1000);
          this.intervals.push(t)
        });

      });
    },
    //跳页
    goPage(currentPage){
        this.loading = true
        this.pageParams.pageNo = currentPage
        this.onProjectList()
    },
    /**
     * @Description: 确定结果
     * @Author: Niklaus
     * @Date: 2021-08-28 14:59:04
     */
    handleConfirm(row){
      this.$ls.set('confirmProject',{
        id: row.id,
        purchaseMethod:row.purchaseMethod,
        quotationMethod: row.quotationMethod,
        processDetailStatus: row.processDetailStatus,
      });
      this.$router.push('/confirmResult')
    },

    /**
     * @Description: 查新报价记录
     * @Author: Niklaus
     * @Return:
     * @Date: 2022-06-07 16:37:56
     * @param {*} projectId
     */
    handleRecord(row,projectId) {
      let data = {
        projectId,
        supplierStoreId: this.queryParam.supplierStoreId
      }
      httpGet(global.gateway + this.urls.priceRecord, data).then(res => {
        if(res.data.success) {
          this.priceRecords = res.data.result.list
          this.priceTreeRecords = res.data.result.treeList
          if(row.quotationMethod == '2')
            this.priceText = '报价优惠率（%）'
          else
            this.priceText = '报价金额'
          // this.priceTreeRecords.forEach(round => {
          //   let roundObj = {roundsNo: round.roundsNo, itemNum:round.list.length}
          // })
          this.showRecord = true
        }
      })
    },

    // spanMethod({ row, column, rowIndex, columnIndex }){
    //   if(columnIndex === 0) {
    //     let count = 1
    //     for (let i = 0; i < this.treeList.length; i++) {
    //       const element = this.treeList[i];
    //       if(row.roundsNo == element.roundsNo) {
    //         count = element.list.length
    //         break
    //       }

    //     }
    //   }
    // },

    /**
     * @Description: 显示终止原因
     * @Author: Niklaus
     * @Return:
     * @Date: 2022-06-06 19:33:23
     * @param {*} reason
     */
    showReason(reason) {
      this.$alert(reason, '终止原因', {
        confirmButtonText: '确定',
      });
    }

  }
}
</script>

<style lang="scss" scoped>
.el-container {
  background-color: #fff;
  height: 100%;
  .el-header {
    display: flex;
    display: -webkit-flex;
    display: -ms-flexbox;
    align-items: center;
    h1 {
      font-size: 23px;
      font-weight: bold;
      margin-right: 30px;
    }
  }
  .el-main {
    // position: relative;
    // width: 100%;
    // overflow: hidden;
    .search {
      display: flex;
      justify-content: flex-start;
      .el-input {
        width: 300px;
      }
    }
    .page-pagination {
      width: 100%;
      margin-top: 50px;
      display: flex;
      justify-content: center;
    }
  }
}
  .priceRecord {
    padding: 20px;
    & >.el-row {
      border: 1px solid red;
    }
  }


</style>
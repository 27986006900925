/*
 * @Descripttion: 日期处理
 * @version: 1.0
 * @Author: Niklaus
 * @Date: 2021-08-27 16:38:37
 */

/**
 * @Description: 返回当前时间日期 yyyy-MM-dd hh24:mi:ss
 * @Author: Niklaus
 * @Date: 2021-08-27 18:03:17
 */
const dateFormat = ( date =  new Date()) => {
  let y = date.getFullYear()
  let M = date.getMonth() +1
  let d = date.getDate()
  let h =date.getHours()
  let m =date.getMinutes()
  let s = date.getSeconds()

  return `${[y,M,d].map(formatNum).join("-")} ${[h,m,s].map(formatNum).join(":")}`
}

const countDown = (start,end) =>{
  let diff =end.getTime() - start.getTime()
  if( diff> 24*60*60*1000) {
    return '>1天'
  }else if(diff<=0){
    return '00:00:00'
  }else{
    let h,m,s;
    h = Math.floor(diff/60/60/1000)
    m = Math.floor(diff/60/1000%60)
    s = parseInt(diff/1000%60)
    return [h,m,s].map(formatNum).join(":")
  }
}

const newCountDown = (start, interval, end, count) => {
  let diff =end.getTime() - start.getTime() - count * interval
  if( diff> 24*60*60*1000) {
    return '>1天'
  }else if(diff<=0){
    return '00:00:00'
  }else{
    let h,m,s;
    h = Math.round(diff/60/60/1000)
    m = Math.round(diff/60/1000%60)
    s = Math.round(diff/1000%60)
    return [h,m,s].map(formatNum).join(":")
  }
}




const formatNum = n =>{
  n = n.toString()
  return n[1] ? n : `0${n}`
}

module.exports = {
  dateFormat,
  countDown,
  newCountDown
}